import * as React from "react";
import styled from 'styled-components';

import SEO from "../components/seo"
import Layout from "../components/layout"

import Colors from '../colors';


const FullHeightSection = styled.section`
  color: ${ Colors.primary };
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 192px - 30px - 48px);
  text-align: center;

  & h2 {
    font-family: Source Serif Pro, serif;
    font-size: 5rem;
    font-weight: 700;
  }

  .contact__details {
    p {
      font-family: Source Serif Pro, serif;
      font-size: 1.5625rem;
      font-weight: 400;
    }
  }

  @media (max-width: 768px) {
    justify-content: flex-start;
    min-height: calc(100vh - 192px - 13px);
    margin-top: 53px;

    & h2 {
      font-size: 2.5rem;
      font-weight: 300;
      text-align: left;
      margin-bottom: 40px;
      width: 100%;
    }

    .contact__details {
      padding-left: 58px;
      width: 100%;

      p {
        font-size: 0.9375rem;
        text-align: left;
      }
    }
  }
`;

const Page = () => {
  return (
    <Layout>
      <SEO title=".aftermath - Home" />
      <FullHeightSection className='contact'>
        <h2>Contact</h2>
        <div className='contact__details'>
          <p>.aftermath research</p>
          <p>spain<code>@</code>after-math.es</p>
        </div>
      </FullHeightSection>
    </Layout>
  );
}

export default Page;
